import { Link } from "gatsby"
import * as React from "react"
import LogoTWWWhite from '../../images/logo-tww-white.png'
import api from '../../services/api'
const Footer = (props) => {

    const handleSuscription = () => {

    }

    return (
        <>
            <p>

            </p>
            <footer
                className="mt-4"
                style={{
                    marginTop: `var(--space-5)`,
                    fontSize: `var(--font-sm)`,
                }}

            >

                <div className="mx-auto w-full my-8 px-4 py-4 text-center text-gray-600">
                    <p className="text-base">
                        Licorería Surco · Licorería Surquillo · Licorería Lince · Licorería La Victoria · Licorería San Isidrio · Licorería La Molina · Licorería Miraflores · Licorería San Borja · Licorería Barranco · Licorería Lima · Licorería Surco · Licorería San Luis ·
                        Licorería Chorrillos · Licorería Ate · Licorería San Miguel · Licorería Pueblo Libre · Licorería Breña · Licorería Magdalena · Licorería Santa Anita · Licorería Lurin · Licorería Perú
                    </p>
                    <p className="pt-2">¡Las Licorerías Unidas en un solo lugar con los mejores licores!</p>
                </div>
                <div className="bg-gradient-to-r from-green-200 via-green-700 to-green-400 h-3 w-full"></div>
                <div className="px-14 pt-10 pb-8 bg-black text-white w-full">
                    <div className="grid grid-cols-12 gap-6 md:gap-8 lg:gap-12">
                        <div className="col-span-12 md:col-span-3 flex flex-col item-center justify-center ">
                            <div className="h-9 flex justify-center mb-8">
                                <img width={200} height={67} className="h-9 w-auto" alt="delicores.com" src='https://d2ja6cy9ij2jx1.cloudfront.net/fit-in/200x67/delicores-logo-white.png' />
                            </div>
                            <div className="flex justify-center space-x-4 mt-6 md:mt-0">
                                <a href="https://www.facebook.com/profile.php?id=61550340011058" target={"_blank"}>
                                    <img className="w-10 h-10" width={10} height={10} alt="icon instagram delicores.com" src="https://d2ja6cy9ij2jx1.cloudfront.net/icono-instagram.svg" />
                                </a>
                                <a href="https://www.instagram.com/delicores_peru/" target={"_blank"}>
                                    <img className="w-10 h-10" width={10} height={10} alt="icon facebook delicores.com" src="https://d2ja6cy9ij2jx1.cloudfront.net/icono-facebook.svg" />
                                </a>
                                <a href="https://www.tiktok.com/@delicores_peru" target={"_blank"}>
                                    <img className="w-9 h-9 invert" width={10} height={10} alt="icon whatsapp delicores.com" src="https://d2ja6cy9ij2jx1.cloudfront.net/icono-tiktok.svg" />
                                </a>
                                <a href="https://wa.me/51975711779" target={"_blank"}>
                                    <img className="w-10 h-10" width={10} height={10} alt="icon whatsapp delicores.com" src="https://d2ja6cy9ij2jx1.cloudfront.net/icono-whatsapp.svg" />
                                </a>
                            </div>
                            <div className="mt-6">
                                <p className="font-thin">
                                    Razón Social: <strong>LICORERIAS PERÚ S.A.C.</strong>
                                </p>
                                <p className="font-thin">
                                    R.U.C: <strong>20611486643</strong>
                                </p>
                            </div>
                        </div>
                        {/*
                        <div className="col-span-12 md:col-span-2">
                            <p className="font-bold text-xl">
                                Nuestra Compañia
                            </p>
                            <ul className="space-y-3 mt-4">
                                <li>
                                    Sobre Nosotros
                                </li>
                                <li>
                                    Tiendas
                                </li>
                                <li>
                                    <Link to="/licoreria-delivery">
                                        Licorería Delivery
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        */}
                        <div className="col-span-12 md:col-span-2">

                            <p className="font-bold text-xl">
                                Servicios al Cliente
                            </p>
                            <ul className="space-y-3 mt-4">
                                <li>
                                    <Link to='/politicas-de-cambios-y-devoluciones'>
                                        Politicas de cambios y devoluciones
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/terminos-y-condiciones'>
                                        Terminos y Condiciones
                                    </Link>
                                </li>
                                {/* 
                                <li>
                                    <p>
                                        Retiro en Tiendas
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Información de Tiendas
                                    </p>
                                </li>
                                */}
                            </ul>

                        </div>
                        <div className="col-span-12 md:col-span-2">
                            <p className="font-bold text-xl">
                                Atención al Cliente
                            </p>
                            <ul className="space-y-3 mt-4">
                                <li>
                                    <a href="tel:+51975711779" className="font-bold text-xl">
                                        <span className="text-lg">+51</span> 975 711 779
                                    </a>
                                </li>
                                <li>
                                    <p className="text-sm">
                                        Lun. - Dom. de 8:00 am a 11:00 pm
                                    </p>
                                </li>
                                <li>
                                    <a className="text-sm" href="mailto:ventas@delicores.com">ventas@delicores.com</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-span-12 md:col-span-3">
                            <p className="font-bold text-xl">
                                Oficinas y Despachos
                            </p>
                            <ul className="space-y-3 mt-4 text-sm">
                                <li>
                                    <a className="text-sm" target={"_blank"} href="https://goo.gl/maps/CeJYLZyUGC1wcChw5">
                                        Calle Arturo Duray 292 Int. 13 - Surquillo - Lima
                                    </a>
                                </li>
                                <li >
                                    Lun. - Vie. de 08:00 am a 10:00 pm
                                </li>
                                <li>
                                    Sab. de 08:00 am a 01:00 pm
                                </li>
                            </ul>
                        </div>
                        <div className="col-span-12 md:col-span-2">
                            {/*
                            <p className="font-bold text-xl">
                                Cava
                            </p>
                            <ul className="space-y-3 mt-4">
                                <li>
                                    <a target={"_blank"} href="https://goo.gl/maps/2BbgpQV8fmSAp48M6">
                                        Jr. Colina 107 - Barranco - Lima
                                    </a>
                                </li>
                                <li>
                                    Mar. - Sáb. de 01:00 pm a 11:00 pm
                                </li>
                            </ul>
                            */}
                            <p className="font-bold text-xl">
                                Realiza tus compras de forma segura
                            </p>
                            <ul className="space-y-3 mt-4">
                                <li>
                                    <a href="tel:+51975711779" className="font-bold text-xl">
                                        <span className="text-lg">+51</span> 975 711 779
                                    </a>
                                </li>

                                <li className="bg-white p-4 rounded-lg mt-6 max-w-[200px]">
                                    <Link to='/libro-de-reclamaciones'>
                                        <img className="w-full lazyload" width={279} height={165} alt="Libro de reclamaciones" src='https://d2ja6cy9ij2jx1.cloudfront.net/fit-in/279x165/libro-de-reclamaciones-35c47757c5.png' />
                                    </Link>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div className="bg-gradient-to-r from-green-400 via-green-800 to-green-200 h-3 w-full"></div>
                <div className=" bg-black text-white font-bold text-lg md:text-2xl text-center py-6">
                    <p className="max-w-6xl mx-auto p-2 md:p-4 tracking-tighter md:tracking-wide leading-8">
                        TOMAR BEBIDAS ALCOHÓLICAS EN EXCESO ES DAÑINO. <br className="hidden md:block" />ESTÁ PROHIBIDA LA VENTA DE ALCOHOL A MENORES DE 18 AÑOS.
                    </p>
                    <div className="bg-black text-white text-base font-medium pt-4 flex justify-center text-center leading-none items-center h-12 py-2">
                        Delicores.com  Ⓡ {new Date().getFullYear()} &middot; Todos los derechos reservados
                    </div>
                    <div className="flex justify-center items-center px-4 py-2">
                        <span className="mr-3 text-xs font-thin">
                            Desarrollado por
                        </span>
                        <a href="https://backap.com.pe/" target="_blank" rel="noreferrer">

                            <img className="h-8 w-auto lazyload" width={104} height={42} alt='TCS' src={'https://cdn.drinkyn.com/fit-in/256x256/logo-bp-ae04ec9f.png'} />
                        </a>
                    </div>
                </div>

            </footer >
        </>
    )
}



export default Footer
